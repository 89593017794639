<template>
  <div class="categories">
    <div class="status-bar">
      {{ status }}
    </div>
    <table
      v-if="categories.length"
      class="table is-striped is-hoverable is-fullwidth"
      :class="{ 'state-loading': loading }"
    >
      <tr>
        <th>Category</th>
        <th>First pre</th>
        <th>Latest pre</th>
        <th>Total pre</th>
      </tr>
      <tbody>
        <CategoryTableRow
          v-for="row in categories"
          :key="row.category"
          :r="row"
        />
      </tbody>
    </table>
  </div>
</template>

<script>
import api from "@/assets/js/api";
import utils from "@/assets/js/utils";
import CategoryTableRow from "@/components/CategoryTableRow.vue";

export default {
  name: "Live",
  components: {
    CategoryTableRow,
  },
  data() {
    return {
      loading: false,
      status: "Loading",
      categories: [],
    };
  },
  created() {
    this.run();
  },
  methods: {
    run() {
      utils.setPageTitle("Categories");
      this.loading = true;
      this.$Progress.start();
      this.status = "Loading";

      window.scrollTo(0, 0);
      const elStart = window.performance.now();
      return api
        .categories()
        .then((data) => {
          if (!data) {
            return;
          }

          const sec = Math.round(window.performance.now() - elStart) / 1000;
          // this.status = `Results ${data.offset + 1}-${
          //   data.offset + data.rowCount
          // } of ${data.total} matches in ${sec} seconds`;
          this.status = `Top ${data.rowCount} categories ordered by pre count in ${sec} seconds`;
          this.categories = data.rows;

          return true;
        })
        .catch((err) => {
          this.status = err.message || "Error while loading categories";
        })
        .finally(() => {
          this.loading = false;
          this.$Progress.finish();
        });
    },
  },
};
</script>
